.swipeCardsContainer {
  margin-top: 20px;
}

.totalAmount {
  text-align: right;
}

.cardSwiped {
  background: rgb(187, 177, 177) !important;
}

.paymentMethods {
  margin-bottom: 10px;
  .paymentMethod {
    padding-right: 10px;
  }
}
